import React from "react"
import Layout from "../../components/Layout"

const ThankYou = () => (
  <Layout showContact={false}>
    <section className="section full-section">
      <div className="container">
        <div className="title has-text-primary mb-6">
          Thank you for contacting with us
        </div>
        <div className="subtitle">We will get back to you shortly!</div>
      </div>
    </section>
  </Layout>
)

export default ThankYou
